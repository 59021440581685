.opLabels-print-container, .spLabels-print-container, .samplesLabels-print-container, .projectConfiguration-print-container {
  position: relative;
}

.opLabel, .spLabel, .sampleLabel {
  padding: 5px;
  border: 1px solid rgb(0,0,0,0.15);
  border-radius: 12px;
  overflow: hidden;
}

.opLabel-divider, .spLabel-divider, .sampleLabel-divider {
  margin: 2px auto 5px auto;
  background: rgba(0, 0, 0, 0.44);
  height: 2px;
  width: 100%;
}

.opLabel-logo, .spLabel-logo {
  min-width: 60%;
  max-width: 60%;
  min-height: 90%;
  max-height: 90%;
  overflow: hidden;
  transform: scale(0.9);
}

.opLabel-logo img, .spLabel-logo img {
  width: 100%;
}

.opLabel-opNumber, .spLabel-spNumber {
  max-width: 40%;
  min-width: 40%;
  text-align: end;
  padding-right: 20px;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
}

.opLabel-secondRow--details, .spLabel-secondRow--details {
  max-width: 75%;
  min-width: 50%;
  font-size: 14px;
}
.spLabel-secondRow--details---samples {
  font-size: 11px;
}

.spLabel-secondRow--details---samples--small {
  font-size: 9px;
}

.opLabel-secondRow--warning, .spLabel-secondRow--warning {
  color: #FF0000;
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  max-width: 30%;
  min-width: 30%;
}

.opLabel-thirdRow--projectInfo, .spLabel-thirdRow--projectInfo {
  max-width: 65%;
  min-width: 65%;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.opLabel-thirdRow--date, .spLabel-thirdRow--date {
  max-width: 30%;
  min-width: 30%;
  font-size: 14px;
}

/* SAMPLES LABELS ONLY */

.sampleLabel-divider {
  margin-bottom: 2px;
}

.sampleLabel {
  text-align: center;
  font-size: 7px;
}

.sampleLabel-title {
  font-weight: 600;
  font-size: 10px;
  color: rgb(0, 137, 150);
}

.sampleLabel-row--regular {
  padding: 2px 0;
}

.sampleLabel-row--small {
  padding: 0 !important;
}

.sampleLabel-row--primary {
  color: rgb(0, 137, 150);
}


.sampleLabel-row--primary--small {
  font-size: 6px !important;
}

.sampleLabel-divider--small {
  margin: 1px auto 2px auto;
  background: rgba(0, 0, 0, 0.44);
  height: 1px;
  width: 100%;
}