.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.colored-toggle-button-group .Mui-selected:nth-child(1),
.colored-toggle-button-group .Mui-selected:nth-child(6) {
  background-color: #e8f5e9;
  color: #81c784;
}

.colored-toggle-button-group .Mui-selected:nth-child(2),
.colored-toggle-button-group .Mui-selected:nth-child(5) {
  background-color: #fffde7;
  color: #fbc02d;
}

.colored-toggle-button-group .Mui-selected:nth-child(3),
.colored-toggle-button-group .Mui-selected:nth-child(4) {
  background-color: #ffebee;
  color: #e57373;
}

.circleForHover:hover + g.tooltip {
  display: block;
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}
