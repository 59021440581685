.Visualizer h5 {
  font-family: Gotham;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

#annotated-floorplan-container {
  display: block;
  position: relative;
  width: 1200px;
  height: calc(833px + 120px);
  transform: scale(0.85) translateX(-8.5%) translateY(-8.5%);
}

#annotated-floorplan-container--logo {
  background-image: url('./Template-for-Floor-Plan.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: -10px;
  width: 1000px;
  height: 100px;
}

#annotated-floorplan-container--img {
  position: absolute;
  top: 120px;
  left: 0px;
}

.Visualizer div.placeable-object-list{
    border-width: 20px;
    border-color: black;
    width:300px;
    
}
  
.Visualizer  .disable-select {
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}
  
.Visualizer  .image-frame{
  transition: transform 150ms ease-in-out;
  border: 2px solid rgb(0, 137, 150);;
}
.Visualizer  .img-overlay-wrap {
    position: relative;
    display: inline-block; /* <= shrinks container to image size */
    /*transition: transform 150ms ease-in-out;
    border: 2px solid rgb(0, 137, 150);;
    */
}
  
.Visualizer .img-overlay-wrap img { /* <= optional, for responsiveness */
     display: block;
     max-width: 100%;
     height: auto;
}
  
.Visualizer .img-overlay-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
  }
  
.Visualizer .img-overlay-wrap .overlay .object{
    position: absolute;
    display: inline-block;
    /*background-color: darkred;*/
    /*olor: white;*/
    cursor: move;
    height: 50px;
    width: 50px;
  
  }
  
.Visualizer input {
    width: 10em;
  
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 9pt;
    border-width: 1px;
    border-color: rgb(180, 221, 161);
    background-color: rgb(245, 249, 207);
    
  
    height: 13pt;
    
  }
.Visualizer ul {
    list-style-type: none;
    margin: 10px;
    padding: 10px;
  
    
  }
.Visualizer  li {
    height: 16pt;
  }
  
  
  
.Visualizer span{
    margin-left:10px;
    margin-right:10px;
    min-width: 100px;

  }
  
.Visualizer   .visualizer-header {
    color: #282c34;
    padding: 16px;
    min-height: 84px;
    display: flex;
  }
 
  
.Visualizer   #logo-panel {
    width: 310px;
  }
  
.Visualizer   #info-panel {
    width: 800px;
    flex:2.2;
  }
  
.Visualizer   #info-panel h1{
    margin-top:0px;
    margin-bottom:8px;
  }
.Visualizer   #info-panel h2{
    margin-top:4px;
    margin-bottom:8px;
  }
.Visualizer   #product-logo {
    position: relative;
    max-width: 240px;
  
  }
  
  
.Visualizer  .App-link {
    color: #61dafb;
  }
  
.Visualizer   .main {
    display:flex;
  }
.Visualizer   .floorplan-selector{
    width: 300px;
    padding-left: 16px;
  }
.Visualizer   .origin-sample-point-list {
    width: 300px;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 12px;
    padding-bottom: 12px;
  }
.Visualizer   .origin-sample-point-list .name-column {
    text-align: left;
      width: 200px;
  }

.Visualizer   .origin-sample-point-list .number-column {
    text-align: left;
    width: 80px;
}

.Visualizer  .origin-sample-point-list .name-field {
    width: 200px;
}
  
.Visualizer .image-canvas{
    width: 80%;
    height: 80vh;
  }
.Visualizer img.floor-plan{
    width:100%;
    
    object-fit:content;
  }
  
.Visualizer   .react-transform-element {
    border-width: 2px;
    border-color: lightcoral;
  }
  
.Visualizer   tr.dirty {
    background-color:rgb(238, 235, 196);
  }
  
.Visualizer   tr.dirty input{
    background-color:rgb(254, 252, 233);
  }
  
  
.Visualizer   .control-group {
    display: inline-block;
    vertical-align: top;
    text-align: left;
  
    padding: 4px;
    width: 100%;
    height: 100%;
    margin: 4px;
  }
.Visualizer   .control {
    display: block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 9pt;
  }
.Visualizer  .control--radio  {
    padding-left: 26px;
  }
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
.Visualizer   .control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 12px;
    width: 12px;
    background: #e6e6e6;
  }
.Visualizer   .control--radio .control__indicator {
    border-radius: 50%;
  }
.Visualizer   .control:hover input ~ .control__indicator,
.Visualizer .control input:focus ~ .control__indicator {
    background: #ccc;
  }
.Visualizer   .control input:checked ~ .control__indicator {
    background: #2aa1c0;
  }
.Visualizer  .control:hover input:not([disabled]):checked ~ .control__indicator,
.Visualizer .control input:checked:focus ~ .control__indicator {
    background: #0e647d;
  }
.Visualizer   .control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
.Visualizer   .control__indicator:after {
    content: '';
    position: absolute;
    display: none;
  }
.Visualizer  .control input:checked ~ .control__indicator:after {
    display: block;
  }
  
.Visualizer  .control--radio .control__indicator:after {
    left: 3px;
    top: 3px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #fff;
  }
.Visualizer  .control--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
  }

.Visualizer .materials-table-wrapper th, td {
  border-width: 0px;
}