.buildingDetailsSection-header-container--text {
  display: flex;
  align-items: center;
}

.buildingDetailsSection-form-container {
  max-width: 50vw;
}

.buildingDetailsSection-header-container {
  margin-bottom: 24px;
}

.buildingDetailsSection-header-container button {
  margin-left: 8px;
}

@media only screen and (max-width: 900px) {
  .buildingDetailsSection-form-container {
    max-width: 95vw;
  }
}

.buildingDetailsSection--form-actions-container {
  margin-top: 24px;
}

.buildingDetailsSection--form-actions-container button {
  min-width: 100px;
  max-width: 140px;
}

.buildingDetailsSection--form-actions-container button:first-child {
  margin-right: 16px;
}

.building-details--form .building-details--form__field {
  margin: 8px 0px;
}

.building-details--form .building-details--form__field label {
  font-weight: 600;
}

/* .building-details--form .building-details--form__field *>* {
  margin-left: 4px;
} */

.building-details--form .building-details--form__field:first-child {
  margin-top: 0px;
}

.building-details--form .building-details--form__field:last-child {
  margin-bottom: 0px;
}