.img-overlay-wrap {
  /* border: solid 1px red; */
  position: relative;
  display: inline-block; /* <= shrinks container to image size */
}

.img-overlay-wrap img {
  /* <= optional, for responsiveness */
  display: block;
  height: auto;
}

.img-overlay-wrap svg {
  position: absolute;
  top: 0;
  left: 0;
}

/* .heatmap-container-new {
  border: solid 1px red;
  transform: scale(0.9);
} */
.heatmap-container-new #gradient-svg {
  z-index: 5000;
  overflow: visible;
}
.heatmap-container-new p  {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3.5em;
}

#floorplan-gradient-box {
  /* border: solid 5px yellowgreen; */
  display: flex;
  flex-direction: row;
  gap: 42px;
}
#heatmap-download-addition {
  margin-bottom: 42px;
}

#heatmap-download-addition p {
  width: 100%;
}
#heatmap-download-addition .significance-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#heatmap-download-addition .significance-box p {
  width: fit-content;
  margin: 0;
}
#heatmap-download-addition .significance-box p:last-child {
  margin-left: 8px;
}
#heatmap-download-addition .significance-box .significance-box--square {
  margin-left: 24px;
  height: 120px;
  width: 120px;
}
.img-overlay-wrap--spOpContainer {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top left;
  overflow: visible;
  font-size: 16px;
}

.newHeatmap #thisHeatmap {
  overflow: hidden;
}

/* 
#heatmap-download-addition * {
  border: solid 10px #2f2f;
} */

/* #gradient-svg {
  border: solid 1px blue;
} */