.square-significance-icon {
  width: 16px;
  height: 16px;
  border: solid 1px rgb(133, 133, 133);
  margin-right: 6px;
  border-radius: 2px;
}

.menu-item--significance-option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}