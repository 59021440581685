.testItem-container {
  min-width: 40vw;
  max-width: 90vw;
  border-radius: 6px;
  background-color: white;
}

.testItem-title {
  display: flex;
  flex-direction: row;
}

.testItem-title--text {
  position: relative;
  left: -24px;
}

.testItem-title--icon {
  height: 24px;
  width: 24px;
  position: relative;
  left: -30px;
  top: 4px;
  color: rgba(0, 0, 0, 0.57);
  cursor: grab;
}

.projectCard {
  min-width: 40vw;
  max-width: 90vw;
  padding-right: 20px;
}

.projectCard-title--icon {
  height: 24px;
  width: 24px;
  position: relative;
  top: 4px;
  color: rgba(0, 0, 0, 0.57);
  cursor: grab;
}