.projectConfiguration-logo {
  width: 100%;
}

.projectConfiguration-logo img {
  width: 40%;
}

.projectConfiguration-projectScope--warningIcon path:first-child {
  color: rgb(249,238,60);
  opacity: 1;
}

.projectConfiguration-projectScope {
  max-width: 100%;
}

.projectConfiguration-standartTable--container {
  max-width: 100%;
}

.projectConfiguration-standartTable--container table {
  border: 1px solid grey;
  min-width: 400px;
}

.projectConfiguration-standartTable--container thead > tr  {
  background:  rgb(0, 137, 150);  
}

.projectConfiguration-standartTable--container th, 
.projectConfiguration-standartTable--container td, 
.projectConfiguration-standartTable--container tr  {
  padding: 4px;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 13.3px;
  letter-spacing: 0.01071em;
  border: 1px solid grey; 
}

.projectConfiguration-standartTable--container thead > tr th  {
  color: white;
}