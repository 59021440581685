.results-upload-box {
  max-width: 480px;
}

@keyframes primary-color-pulse {
  0% {color: #008996}
  50% {color: rgb(0, 137, 150, 0.3)}
  100% {color: #008996}
}

.icon-btn-loading-color {
  color: #008996;
  animation-name: primary-color-pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}